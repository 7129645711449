import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [0,4];

export const dictionary = {
		"/(public)": [~5,[2]],
		"/(public)/a-propos": [6,[2]],
		"/admin": [~10,[3]],
		"/admin/painting/add": [13,[3]],
		"/admin/painting/[id]": [11,[3,4]],
		"/admin/painting/[id]/images": [12,[3,4]],
		"/(public)/contact": [7,[2]],
		"/(public)/expositions": [8,[2]],
		"/login": [14],
		"/(public)/painting/[id]": [~9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';